import styled from '@mui/system/styled';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
export const PageContainer = styled(Box)(({theme})=>({
   'paddingTop': theme.spacing(1),
   'paddingBottom': theme.spacing(1)
}))

export const FlexGrowGrid = styled(Grid)({
   'flexGrow': 1
})

export const FlexGrowColumnBox = styled(Box)({
   'display': 'flex',
   'flexGrow': 1,
   'flexDirection': 'column'
})

export const FieldContainer = styled('div')(({theme})=>({
   '& > *':{
      'marginTop': theme.spacing(2)
   },
   'FormControl':{
      'root': {
         'margin': 'none'
      }
   }
}));

export const SpacedBetweenBox = styled(Box)({
   display:'flex',
   justifyContent: 'space-between',
   alignItems:'center'
})

export const CenterAlignedBox = styled(Box)({
   'display': 'flex',
   'justifyContent': 'center',
   'alignItems': 'center'
})

export const PaperFlat = (props)=>{
   return <Paper elevation={0} sx={{padding:1, ...props.sx}} {...props}>
      {props.children}
   </Paper>
}

export const FlexGrowPaperFlat = (props)=>{
   return <Paper elevation={0} {...props} sx={{padding:1, display:'flex', 'flexGrow': 1, ...props.sx}}>
      {props.children}
   </Paper>
}