
import { createTheme } from '@mui/material/styles';
import dark from './palettes/dark'
import LatoRegular from '../assets/fonts/Lato-Regular.ttf';
// const defaultTheme = createTheme();
export const topBarHeight = 90;

export const getTheme = (paletteMode)=>{
   return createTheme({
      'palette': dark,
      'mixins': {
         topBarOffset: {
            'marginTop': `${topBarHeight}px`
         },

      },
      typography: {
         fontFamily: ['LatoRegular'].join(','),
      },
      'components': {
         'MuiCssBaseline':{
            'styleOverrides':{
               '@font-face': {
                  'fontFamily': "LatoRegular", 
                  'src': `url(${LatoRegular})`
               },
               'html': {
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight:'100%',
                  fontFamily: 'LatoRegular',
               },
               'body': {
                  // boxShadow: 'inset 0px 0px 12px 8px rgba(255,255,255, 0.1)',
                  background: 'linear-gradient(165deg, rgba(49,80,111,1) 5%, rgba(14,46,77,1) 24%, rgba(6,27,46,1) 57%, rgba(0,0,0,1) 102%)',
                  // background: 'linear-gradient(165deg, rgba(62,93,123,1) 0%, rgba(6,36,64,1) 51%, rgba(0,0,0,1) 100%)',
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow:1,
                  fontFamily: 'LatoRegular',
               },
               '#root':{
                  display: 'flex',
                  flexGrow: 1,
               }
            }
         },
         'MuiAccordion': {
            'styleOverrides':{
               'root': {
                  'background': "rgba(61,61,61,1)",
                  'borderTop': `1px solid rgba(51,51,51,1)`,
                  'borderBottom': `1px solid rgba(51,51,51,1)`,
                  '&:not(:last-child)': {
                     borderBottom: 0,
                  },
               }
            }
         },
         'MuiIconButton':{
            'styleOverrides':{
               'root':{
                  'color':'rgba(255, 255, 255, 0.9)'
               }
            }
         }
      }
   });
}