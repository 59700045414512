import {useSelector} from 'react-redux'
import { CenterAlignedBox, FlexGrowColumnBox, PageContainer } from "../../components/styled/containers";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useGetAppsQuery } from "../../app/api";
import AutoSizer from 'react-virtualized-auto-sizer';
import {useSendRemoteFunctionMutation} from '../../app/api/index';
import debounce from 'lodash.debounce';
import appimages from '../../assets/app_icons/index';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from "react";
import ReactGA from 'react-ga4';

const AppsPage = (props)=>{

   const {data, isLoading, isSuccess} = useGetAppsQuery();

   useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Apps Page"  });
    }, []);

   return <PageContainer sx={{flexGrow:1, display:'flex', flexDirection:'column'}}>
      <FlexGrowColumnBox sx={(theme)=>({
         display:'flex',
         flexDirection:'column',
         flexGrow:1,
      })}>
         {isLoading ? (<CenterAlignedBox sx={{flexGrow:1, alignContent:'center', justifyContent:'center'}}>
                              <CircularProgress/>
                           </CenterAlignedBox>)
         :
            <AutoSizer>
            {({height, width})=>{
                        return <Box sx={{height:height, width:width, 'overflow':'auto'}}>
                           {isSuccess ? (<AppsGrid height={height} apps={data.apps}/>) : <CenterAlignedBox>Error retrieving app list</CenterAlignedBox>}
                        </Box>
                     }}
            </AutoSizer>
         }

      </FlexGrowColumnBox>
   </PageContainer>
}

const AppsGrid = (props)=>{
   const serialNumber = useSelector((state)=>{
      return state.root.activeStb;
   })
   const [sendAction] = useSendRemoteFunctionMutation();
   const handleAppSelect = debounce((appName, event)=>{
      let payload = {'serialNumber': serialNumber, 'action': {
         type:'LAUNCH_APP',
         app:appName
      }};
      sendAction(payload)
      ReactGA.event({
         category: 'app_launch',
         action: appName+' Launch'
       });
   }, 120)

   return <Grid container>
      {props.apps.map((app)=>{
         if (app.name === 'Cinema On Demand'){
            return null
         }
         return <Grid key={app.name} xs={6} item sx={(theme)=>({
            background: 'black',
            display:'flex',
            border: `1px solid ${theme.palette.outlines.main}`,
            height: (props.height/3)+"px",
            flexGrow:1,
            justifyContent:'center' })}>
            <AppTile appName={app.name} onClick={handleAppSelect}/>
         </Grid>
      })}
   </Grid>
}



const AppTile = (props)=>{
   return <CenterAlignedBox sx={{'padding': '20px'}} onClick={props.onClick.bind(this, props.appName)}>
      <img style={{width:'100%', maxHeight: '100%'}} src={appimages[props.appName]} alt={props.appName}/>
   </CenterAlignedBox>
}

export default AppsPage;