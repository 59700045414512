import Box from '@mui/material/Box'
import UpArrow from '@mui/icons-material/KeyboardArrowUp'
import DownArrow from '@mui/icons-material/KeyboardArrowDown'
import RemoteKey from '../../../components/controls/RemoteKey'
import { FlexGrowColumnBox } from '../../../components/styled/containers'


const wrapperStyle = {
   'width':"57%",
   'height':"34vw",
   'maxHeight': '200px',
   'display': 'flex',
}

const UpDownButtons= (props)=>{
   let margin = null;
   if (props.left){
      margin = {'marginLeft': 'auto'}
   }
   else if(props.right){
      margin = {'marginRight': 'auto'}
   }
   return <Box sx={{
         'flexGrow': 1,
      }}>
         <Box id='volwrapper' sx={{...wrapperStyle, ...margin }}>
            {/* <FlexGrowColumnBox sx={{border: '1px dashed lightblue'}}> */}
            <FlexGrowColumnBox >
               <FlexGrowColumnBox sx={{
                     textAlign:'center',
                     'borderTop': '1px solid rgb(9,52,94)',
                     'borderRadius': '50% 50% 0% 0%',
                     borderLeft:"1px solid rgb(9,52,94)",
                     borderRight:"1px solid rgb(9,52,94)",
                     background:'rgb(5,30,52)',
                     justifyContent: "center"
                  }}>
                  <RemoteKey buttonKey={props.upKey} onKeyPress={props.onUp}>
                     <UpArrow/>
                  </RemoteKey>
               </FlexGrowColumnBox>
               <Box sx={{
                  textAlign:'center',
                  borderLeft:"1px solid rgb(9,52,94)",
                  borderRight:"1px solid rgb(9,52,94)",
                  'lineHeight': '3px', 
                  overflow: 'visible',
                  zIndex: '100',
                  'fontSize': '0.7rem'
               }}>
                  {props.name}
               </Box>
               <FlexGrowColumnBox sx={{textAlign:'center',
                     borderBottom:"1px solid rgb(9,52,94)",
                     borderLeft:"1px solid rgb(9,52,94)",
                     borderRight:"1px solid rgb(9,52,94)",
                     background:'rgb(5,30,52)',
                     'borderRadius': '0% 0% 50% 50%',
                     justifyContent: "center"
                  }}>
                  <RemoteKey buttonKey={props.downKey} onKeyPress={props.onDown}>
                     <DownArrow/>
                  </RemoteKey>
               </FlexGrowColumnBox>
            </FlexGrowColumnBox >
         </Box>
   </Box>
}

export default UpDownButtons