import { configureStore, combineReducers, isRejectedWithValue} from '@reduxjs/toolkit';
import root from './reducers/index';
import {api} from './api/index'

const mainReducer = combineReducers({
  root: root.reducer,
  [api.reducerPath]: api.reducer
})

export const rtkQueryErrorHandler = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    if(action.payload && action.payload.status === 401){
      if(action.meta.arg.endpointName !== 'init' && action.meta.arg.endpointName !== 'auth'){
        window.location.reload();
      }
    }
    else if(action.meta) {
      if(action.meta.arg.endpointName === 'sendKey' || action.meta.arg.endpointName === 'sendRemoteFunction'){
      const event = new Event('sendActionError')
        window.dispatchEvent(event)
      }
    }

  }
  return next(action)
}

export const store = configureStore({
  reducer: mainReducer,
  middleware: (getDefaultMiddleware)=>getDefaultMiddleware().concat(api.middleware).concat(rtkQueryErrorHandler)
});
