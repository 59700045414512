import React, {useEffect, useRef, useState} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import {getTheme} from './styles/theme'
// import TopNavBar from './components/TopNavBar';
import MainContentContainer from './components/MainContentContainer';
import {shallowEqual, useSelector } from 'react-redux';
import { css, keyframes } from '@mui/system';
import {styled} from '@mui/material/styles';
import ReactGA from 'react-ga4';

// const errorBlink = keyframes`
//    0%:{
//       box-shadow: inset 0px 0px 12px 8px rgba(255,255,255, 0.1);
//    }
//    50%:{
//       box-shadow: inset 0px 0px 12px 8px rgba(255,0,0, 0.1);
//    }
//    100%:{
//       box-shadow: inset 0px 0px 12px 8px rgba(255,255,255, 0.1);
//    }
// `
const errorBlink = keyframes`
   0% {
      box-shadow: inset 0px 0px 12px 8px rgba(255,255,255, 0.1);
   }
   50% {
      box-shadow: inset 0px 0px 12px 8px rgba(255,0,0, 0.5);
   }
   100% {
      box-shadow: inset 0px 0px 12px 8px rgba(255,255,255, 0.1);
   }
`

const AnimatedBox = styled(Box)`
   display:flex;
   flex-grow:1;
   flex-direction:column;
`

const normal = css`
   box-shadow: inset 0px 0px 12px 8px rgba(255,255,255, 0.1);
`

const animate = css`
      animation: ${errorBlink} 500ms ease infinite
`

const App = (props)=>{
   const containerRef = useRef();
   const [errorOccured, setErrorOccured] = useState(false)
   let appState = useSelector((state)=>{
      return {
         loggedIn: state.root.loggedIn,
         palette: state.root.colorPalette
      }
   }, shallowEqual)

   const handleKeyError = (event)=>{
      setErrorOccured(true)
      setTimeout(()=>{
         setErrorOccured(false)
      }, 500)
   }

   useEffect(()=>{
      window.addEventListener('sendActionError', handleKeyError)
      //ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      return () => {
         window.removeEventListener('keydown', handleKeyError);
      }
   })

   return <ThemeProvider theme={getTheme(appState.palette)}>
      <CssBaseline/>
      <AnimatedBox ref={containerRef} sx={errorOccured ? animate : normal}>
         <MainContentContainer/>
      </AnimatedBox>
  </ThemeProvider>
}

export default App;
