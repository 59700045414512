//src/app/api/auth
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
// console.log("BASE_URL: " + process.env.REACT_APP_BASE_API_URL)

export const api = createApi({
   baseQuery: fetchBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_API_URL,
      credentials:'include',
      mode: 'cors',
      validateStatus: (response)=>{
         return response.status >= 200 && response.status <= 299
      },
   }),
   tagTypes:[],
   endpoints: (builder)=>({
      getChannels: builder.query({
         query:(token)=>({
            'url': '/remote/channels',
            'method': 'GET',
         })
      }),
      getApps: builder.query({
         query:(token)=>({
            'url': '/remote/apps',
            'method': 'GET',
         })
      }),
      disconnect: builder.query({
         query:()=>({
            'url': '/remote',
            'method': 'DELETE',
         })
      }),
      sendKey: builder.mutation({
         query: (payload)=>({
            url:"/remote/keypress",
            method:"POST",
            body:payload
         })
      }),
      sendRemoteFunction: builder.mutation({
         query: (payload)=>({
            url:"/remote/action",
            method:"POST",
            body:payload
         })
      })
   })
});

export const {
   useGetAppsQuery,
   useGetChannelsQuery,
   useLazyDisconnectQuery,
   useSendKeyMutation,
   useSendRemoteFunctionMutation
} = api