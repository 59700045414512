//src/app/api/auth
import {api} from './index';

export const authApi = api.injectEndpoints({
   endpoints: (builder)=>({
      init: builder.query({
         query: ()=>({
            url:'/remote/init',
            method:"GET"
         })
      }),
      auth: builder.query({
         query: (token)=>({
            url:'/auth',
            method:"GET",
            headers: {
               'X-API-TOKEN': token
            }
         })
      }),
   }),
   overrideExisting: false,
});

export const {
   useInitQuery,
   useLazyInitQuery,
   useLazyAuthQuery
} = authApi;

