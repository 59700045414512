import { useSelector } from "react-redux";
import { CenterAlignedBox } from "../../components/styled/containers";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useGetChannelsQuery } from "../../app/api";
import AutoSizer from 'react-virtualized-auto-sizer';
import icons from '../../assets/channel_icons/index';
import {useSendRemoteFunctionMutation} from '../../app/api/index';
import debounce from 'lodash.debounce';
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import ReactGA from 'react-ga4';

const GuidePage = (props)=>{
   const {data, isLoading, isSuccess} = useGetChannelsQuery();
   
   useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Guide Page"  });
    }, []);

   return <CenterAlignedBox sx={{flexGrow:1, display: 'flex', flexDirection:'column'}}>
      <Box sx={{display:'flex',
         flexDirection:'column',
         flexGrow:1, width:'100%'}}>
         {isLoading ? (<CenterAlignedBox sx={{flexGrow:1, alignContent:'center', justifyContent:'center'}}>
                              <CircularProgress/>
                           </CenterAlignedBox>)
         :
         <AutoSizer>
         {({height, width})=>{
            return <Box sx={{height:height, width:width, 'overflow':'auto'}}>
               {isSuccess ? (<ChannelsList channels={data.lineup}/>) : (<CenterAlignedBox>Error retrieving channel list</CenterAlignedBox>)}
            </Box>
         }}
         </AutoSizer>}
      </Box>
   </CenterAlignedBox>
}

const ChannelsList = (props)=>{
   const serialNumber = useSelector((state)=>{
      return state.root.activeStb;
   })
   const [sendAction] = useSendRemoteFunctionMutation();
   const handleAppSelect = debounce((logical, event)=>{
      let payload = {'serialNumber': serialNumber, 'action': {
         type: 'SET_CURRENT_CHANNEL',
         logicalNumber: logical
      }};

      sendAction(payload);
      ReactGA.event({
         category: 'guide_select',
         action: 'Channel Select'
       });
   }, 120)

   useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

   return <Grid container>
      {props.channels.map((channel)=>{
         return <Grid key={channel.logicalChannel+channel.channelName} xs={12} item 
               sx={{
                  borderBottom: '1px solid transparent',
                  height: "48px",
                  display:'flex', 
                  // justifyContent:'space-between'
               }}
               onClick={handleAppSelect.bind(this, channel.logicalChannel)}>
            <CenterAlignedBox sx={{...cellStyle, maxWidth: '50px', background: 'rgba(46, 76, 106, 0.3)'}}>
               <Box></Box>
               {channel.logicalChannel}
            </CenterAlignedBox>
            <CenterAlignedBox sx={{...cellStyle, maxWidth: '60px', background: 'rgba(255, 255, 255, 0.1)'}}>
                  <img style={{ padding: '14px', maxWidth: '70px', maxHeight: '60px' }} src={icons[channel.channelIcon]} alt={props.channelName}/>
            </CenterAlignedBox>
            <CenterAlignedBox sx={{...cellStyle, background: 'rgba(255, 255, 255, 0.1)'}}>
               {channel.channelName}
            </CenterAlignedBox>
         </Grid>
      })}
   </Grid>
}

const cellStyle = {
   'flexGrow': 1
}


export default GuidePage;