import { FlexGrowColumnBox } from "./styled/containers"
import { useSelector } from "react-redux";
import { useLocation, useNavigate} from "react-router";
import {Route, Routes, Navigate} from 'react-router-dom';
import TopNavBar from "../components/TopNavBar";
import SideBar from "../components/SideBar";
import BottomNavBar from "../components/BottomNavBar";
import PrivateRoute from "./PrivateRoute";
import PreAuthPage from "../features/PreAuthPage";
import RemotePage from "../features/remote/RemotePage";
import GuidePage from "../features/guide/GuidePage";
import DevicePage from "../features/devices/DevicesPage";
import AppsPage from "../features/apps/AppsPage";
import { useEffect } from "react";
import {PageContainer} from '../components/styled/containers'
import { useInitQuery, useLazyAuthQuery, useLazyInitQuery } from "../app/api/auth";
import * as Sentry from '@sentry/react';
import { usePageTracking } from "../utils/hooks";
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const MainContentContainer = (props)=>{
   const location = useLocation();
   const navigate = useNavigate();
   usePageTracking();
   const authenticated = useSelector((state)=>{
      return state.root.authenticated
   })
   const {isSuccess, error} = useInitQuery()
   const [triggerInitQuery] = useLazyInitQuery();
   const [authQuery] = useLazyAuthQuery()

   useEffect(()=>{
      if(error){
         if(error.status === 'FETCH_ERROR' || error.data.error === 'UNAUTH' || error.data.error === 'BLKTOKEN'){
            console.log("Unauthorized");
            let params = new URLSearchParams(location.search)
            if(params.get('token') !== null){
               authQuery(params.get('token')).then((result)=>{
                  if(result.status === 'fulfilled'){
                     triggerInitQuery();
                     navigate('/remote');
                  }
               }).catch((error)=>{
                  console.log(error);
               })

            }
         }
      }
      else if (isSuccess){
         console.log("Sucessful init");
         navigate('/remote');
      }
   }, [error, isSuccess]);
   return <FlexGrowColumnBox sx={(theme)=>(theme.mixins.topBarOffset)}>
      {authenticated ? <TopNavBar/> : null }
      {authenticated ? <SideBar/> : null }
      <PageContainer sx={{
         'display': 'flex',
         'flexDirection': 'column',
         'flexGrow': 1,
         // 'border': '1px dashed green',
         }}>
         <SentryRoutes>
            <Route exact path='/' element={<PreAuthPage/>}/>
            <Route exact path='/remote' element={<PrivateRoute><RemotePage/></PrivateRoute>}/>
            <Route exact path='/apps' element={<PrivateRoute><AppsPage/></PrivateRoute>}/>
            <Route exact path='/guide' element={<PrivateRoute><GuidePage/></PrivateRoute>}/>
            <Route exact path='/devices' element={<PrivateRoute><DevicePage/></PrivateRoute>}/>
            <Route path="*" element={<Navigate to="/" replace />} />
         </SentryRoutes>
         {authenticated ? <BottomNavBar/> : null}
      </PageContainer>
   </FlexGrowColumnBox>
}

export default MainContentContainer