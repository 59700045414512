import styled from '@mui/system/styled'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import Circle from '@mui/icons-material/CircleOutlined';
import UpArrow from '@mui/icons-material/KeyboardArrowUp';
import DownArrow from '@mui/icons-material/KeyboardArrowDown';
import LeftArrow from '@mui/icons-material/KeyboardArrowLeft';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import RemoteKey from '../../../components/controls/RemoteKey';
import { FlexGrowColumnBox } from '../../../components/styled/containers';

const CenterAlignedGrid =styled(Grid)(({theme})=>({
   'display': 'flex',
   'alignItems': 'center',
   'justifyContent':'center'
}));
//background: rgb(43,77,112);
// background: linear-gradient(133deg, rgba(43,77,112,1) 0%, rgba(7,38,69,1) 100%);
const NavigationControls = (props)=>{
   return <Box id='nav-controls' sx={{
      'background': 'linear-gradient(178deg, rgba(43,77,112,0.5) 16%, rgba(7,38,69,0.1) 89%)',
      'border': '1px solid rgba(13, 51, 88, 0.5)',
      'borderRadius': '10px',
      'display': 'flex',
      'flexGrow': 1,
      'height': props.height,
      'width': props.width
    }}>
       <FlexGrowColumnBox container direction='column'>
            <Grid sx={{'flexGrow': 1}} item container>
               <Grid xs={4}></Grid>
               <CenterAlignedGrid xs={4}>
                  <RemoteKey buttonKey='UP' onKeyPress={props.onButtonPress}>
                     <UpArrow/>
                  </RemoteKey>
                  {/* <IconButton onClick={props.onButtonPress.bind(this, 'UP')}>
                  </IconButton> */}
               </CenterAlignedGrid>
               <Grid xs={4}></Grid>
            </Grid>
            <Grid sx={{'flexGrow': 1}} item container>
               <CenterAlignedGrid xs={4}>
                  <RemoteKey buttonKey='LEFT' onKeyPress={props.onButtonPress}>
                     <LeftArrow/>
                  </RemoteKey>
               </CenterAlignedGrid>
               <CenterAlignedGrid xs={4}>
                  <RemoteKey buttonKey='ENTER' onKeyPress={props.onButtonPress}>
                     <Circle/>
                  </RemoteKey>
               </CenterAlignedGrid>
               <CenterAlignedGrid xs={4}>
                  <RemoteKey buttonKey='RIGHT' onKeyPress={props.onButtonPress}>
                     <RightArrow/>
                  </RemoteKey>
               </CenterAlignedGrid>
            </Grid>
            <Grid sx={{'flexGrow': 1}} item container>
               <Grid xs={4}></Grid>
               <CenterAlignedGrid xs={4}>
                  <RemoteKey buttonKey='DOWN' onKeyPress={props.onButtonPress}>
                     <DownArrow/>
                  </RemoteKey>
               </CenterAlignedGrid>
               <Grid xs={4}></Grid>
            </Grid>
       </FlexGrowColumnBox>
   </Box>
}

export default NavigationControls;