import { CenterAlignedBox } from "../components/styled/containers";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import ReactGA from 'react-ga4';

const PreAuthPage = (props)=>{

   useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Pre-Auth Page" });
    }, []);

   return <CenterAlignedBox sx={{flexGrow:1}}>
      <Typography variant="h6">Please scan QR Code to begin</Typography>
   </CenterAlignedBox>
}

export default PreAuthPage;