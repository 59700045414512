import amazon from './Amazon.svg'
import bluetooth from './Bluetooth.svg'
import crackle from './crackle.svg'
import hbogo from './hbo.svg'
import hulu from './hulu.svg'
import netflix from './Netflix.svg'
import pandora from './pandora.svg'
import showtime from './Showtime.svg'
import youtube from './youtube.svg'

const images = {
   'Amazon Prime Video': amazon,
   'Bluetooth': bluetooth,
   'Crackle': crackle,
   'HBO GO': hbogo,
   'Hulu': hulu,
   'Netflix': netflix,
   'Pandora': pandora,
   'Showtime': showtime,
   'YouTube': youtube
} 

export default images