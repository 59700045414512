import IconButton from '@mui/material/IconButton'

const RemoteKey = (props)=>{
   return <IconButton {...props} disableRipple onClick={(event)=>{
      props.onKeyPress(props.buttonKey, event);
   }}>
      {props.children}
   </IconButton>
}

export default RemoteKey