import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {store} from './app/store';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import {BrowserRouter as Router} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import config from './utils/config';
import { BrowserTracing } from "@sentry/tracing";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";


if(config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    release: 'controls-client@' + process.env.REACT_APP_VERSION,
    integrations: [
      Sentry.replayIntegration(),
      new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              React.useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes,
          ),
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: config.sentrySampleRate, // Be sure to lower this in production
  });
}

const trackingId = "G-L492WVJ1T4"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
          <App />
      </Router>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
