//src/app/reducers/index.js
import {createSlice} from '@reduxjs/toolkit';
import {authApi} from '../api/auth';
const root = createSlice({
   name: 'root',
   initialState: {
      appInitialized: false,
      appInitMessage: null,
      authenticated: false,
      menuOpened: false,
      activeStb: null,
      devices: {}
   },
   reducers: {
      openMenu: (state, {payload})=>{
         state.menuOpened = payload
      },
      setActiveDevice: (state, {payload})=>{
         state.activeStb = payload
      }

   },
   extraReducers: (builder)=>{
      builder.addMatcher(authApi.endpoints.init.matchFulfilled, (state, {payload})=>{
         console.log(payload);
         state.appInitialized = true;
         state.authenticated = true;
         state.activeStb = payload.source;
         state.devices = payload.stbs;
      })
      builder.addMatcher(authApi.endpoints.auth.matchFulfilled, (state, {payload})=>{
         state.appInitialized = true;
         state.authenticated = true;
         state.activeStb = payload.source;
      })
      builder.addMatcher(authApi.endpoints.auth.matchRejected, (state, {payload})=>{
         state.appInitialized = true;
         state.authenticated = false;
      })
   }
});

export const {switchPalette, openMenu, setActiveDevice} = root.actions;

export default root;