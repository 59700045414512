import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';
import blue from '@mui/material/colors/blue';
import yellow from '@mui/material/colors/yellow';
import { TinyColor } from '@ctrl/tinycolor';

const defaultBackgroundColor = new TinyColor("#121212")

const dark = {
  "common": {
    "black": "#000",
    "white": "#fff"
  },
  "background": {
    "default": defaultBackgroundColor.toString(),
    "paper": defaultBackgroundColor.toString(),
    "subpaper": defaultBackgroundColor.lighten(20).toString(),
    "subpaper2": defaultBackgroundColor.lighten(20).toString(),
    "menu": defaultBackgroundColor.lighten(10).toString(),
    "menu2": defaultBackgroundColor.lighten(20).toString(),
    'oddrow': defaultBackgroundColor.lighten(20).toString()
  },
  "primary": {
    "veryLight": "rgba(34, 84, 156, 0.2)",
    "light": "#2864b8",
    "main": "#2864b8",
    "dark": "#22549C",
    "contrastText": "#fff"
  },
  "secondary": {
    "light": "rgba(230, 111, 49, 1)",
    "main": "rgba(230, 111, 49, 1)",
    "dark": "rgba(230, 111, 49, 1)",
    "contrastText": "#fff"
  },
  "error": {
    "light": "#e57373",
    "main": "#f44336",
    "dark": "#d32f2f",
    "contrastText": "#fff"
  },
  "outlines":{
     'main': 'rgba(224,224,224,.2)',
     'dark': 'rgba(64,64,64,.6)',
     'field': 'rgba(224,224,224,.3)',
     'button': 'rgba(224,224,224,.9)',
     'disabled':'rgba(224,224,224,.6)'
  },
  "text": {
    "primary": "rgb(255, 255, 255)",
    "secondary":   "rgba(255,255, 255, 0.7)",
    "disabled":  "rgba(255, 255, 255, 0.5)",
    "hint": "rgba(255, 255, 255, 0.5)",
    "bright": "rgb(255, 255, 255)",
    "green": green[800],  
    "red": red[800],
    'yellow':yellow[800]
 },
 "status":{
    'green': '#3E8E41',
    'blue': '##2864B8',
    'yellow': "#DEB252",
    'red':  red[800],
    'grey': 'rgb(74 74 74)'
 },
 "highlight": {
    'green': green[300],
    'blue': blue[300],
    'yellow': '#DEB252',
    'red':  red[800]
 },
 "action":{
    'select': 'rgba(6, 6, 6, 0.58)',
    'hover':  'rgba(6, 6, 6, 0.40)'
 },
 "chartPalette":[
   '#3E8E41',
   '#2864B8',
   '#3678D3',
   '#588EDA',
   '#79A5E2'
 ]
}

export default dark;