//this file is autogenerated by ./scripts/channel_icon_index_builder.js
import icon0 from './abc.png';
import icon1 from './abcfam.png';
import icon2 from './ae.png';
import icon3 from './amc.png';
import icon4 from './animal.png';
import icon5 from './anttv.png';
import icon6 from './axs.png';
import icon7 from './bbca.png';
import icon8 from './bet.png';
import icon9 from './bigten.png';
import icon10 from './bloomberg.png';
import icon11 from './bounce.png';
import icon12 from './bravo.png';
import icon13 from './buzzr.png';
import icon14 from './caribvision.png';
import icon15 from './cartoon.png';
import icon16 from './cbs.png';
import icon17 from './cbssports.png';
import icon18 from './cctv4.png';
import icon19 from './cmt.png';
import icon20 from './cnbc.png';
import icon21 from './cnn.png';
import icon22 from './cnnespanol.png';
import icon23 from './comedy.png';
import icon24 from './comet.png';
import icon25 from './cooking.png';
import icon26 from './cozitv.png';
import icon27 from './cspan.png';
import icon28 from './cspan2.png';
import icon29 from './cw.png';
import icon30 from './dcw50.png';
import icon31 from './discovery.png';
import icon32 from './disney.png';
import icon33 from './e.png';
import icon34 from './epix.png';
import icon35 from './epix2.png';
import icon36 from './epix3.png';
import icon37 from './espn.png';
import icon38 from './espn2.png';
import icon39 from './espncl.png';
import icon40 from './espnd.png';
import icon41 from './espnnews.png';
import icon42 from './espnu.png';
import icon43 from './familygekijyo.png';
import icon44 from './food.png';
import icon45 from './fox.png';
import icon46 from './foxbus.png';
import icon47 from './foxnews.png';
import icon48 from './france24.png';
import icon49 from './freeform.png';
import icon50 from './fs1.png';
import icon51 from './fsdet.png';
import icon52 from './fsoh.png';
import icon53 from './fx.png';
import icon54 from './fxm.png';
import icon55 from './fyi.png';
import icon56 from './golf.png';
import icon57 from './h&i.png';
import icon58 from './hallmark.png';
import icon59 from './hallmarkmovies.png';
import icon60 from './hbo.png';
import icon61 from './hbo2.png';
import icon62 from './hbocom.png';
import icon63 from './hbofam.png';
import icon64 from './hbosig.png';
import icon65 from './hbozo.png';
import icon66 from './hdnmv.png';
import icon67 from './hgtv.png';
import icon68 from './history.png';
import icon69 from './hln.png';
import icon70 from './hunansatellite.png';
import icon71 from './ifc.png';
import icon73 from './ion.png';
import icon74 from './justice.png';
import icon75 from './lifetime.png';
import icon76 from './lmn.png';
import icon77 from './marriott.png';
import icon78 from './marriottBonvoyTV_Logo.png';
import icon79 from './mbc.png';
import icon80 from './metv.png';
import icon81 from './mlb.png';
import icon82 from './mnt.png';
import icon83 from './msg.png';
import icon84 from './msnbc.png';
import icon85 from './mtv.png';
import icon86 from './mtv2.png';
import icon87 from './mtvLive.png';
import icon88 from './my20.png';
import icon89 from './my9.png';
import icon90 from './nasa.png';
import icon91 from './natgeo.png';
import icon92 from './nba.png';
import icon93 from './nbc.png';
import icon94 from './nbcsp.png';
import icon95 from './nbcsports.png';
import icon96 from './nesn.png';
import icon97 from './netflixSmall.png';
import icon98 from './nfl.png';
import icon99 from './nhl.png';
import icon100 from './nick.png';
import icon101 from './nicktoons.png';
import icon102 from './ny1.png';
import icon103 from './own.png';
import icon104 from './oxygen.png';
import icon105 from './palladia.png';
import icon106 from './pbs.png';
import icon107 from './phoenixinfonews.png';
import icon108 from './rai.png';
import icon109 from './science.png';
import icon110 from './showtime.png';
import icon111 from './showtime2.png';
import icon112 from './showtimeextreme.png';
import icon113 from './sny.png';
import icon114 from './speed.png';
import icon115 from './spike.png';
import icon116 from './starz.png';
import icon117 from './syfy.png';
import icon118 from './tbs.png';
import icon119 from './tcm.png';
import icon120 from './telemundo.png';
import icon121 from './thistv.png';
import icon122 from './tlc.png';
import icon123 from './tmc.png';
import icon124 from './tnt.png';
import icon125 from './travel.png';
import icon126 from './tru.png';
import icon127 from './tuff.png';
import icon128 from './tvland.png';
import icon129 from './twc.png';
import icon130 from './unimas.png';
import icon131 from './univision.png';
import icon132 from './usa.png';
import icon133 from './velocity.png';
import icon134 from './vh1.png';
import icon135 from './wapa.png';
import icon136 from './wapa2.png';
import icon137 from './we.png';
import icon138 from './weta.png';
import icon139 from './weta26.png';
import icon140 from './wetakids.png';
import icon141 from './wetauk.png';
import icon142 from './wipr.png';
import icon143 from './wmtj.png';
import icon144 from './yes.png';
//Image lookup by filename object
let imageLookup = {
		'abc.png': icon0,
		'abcfam.png': icon1,
		'ae.png': icon2,
		'amc.png': icon3,
		'animal.png': icon4,
		'anttv.png': icon5,
		'axs.png': icon6,
		'bbca.png': icon7,
		'bet.png': icon8,
		'bigten.png': icon9,
		'bloomberg.png': icon10,
		'bounce.png': icon11,
		'bravo.png': icon12,
		'buzzr.png': icon13,
		'caribvision.png': icon14,
		'cartoon.png': icon15,
		'cbs.png': icon16,
		'cbssports.png': icon17,
		'cctv4.png': icon18,
		'cmt.png': icon19,
		'cnbc.png': icon20,
		'cnn.png': icon21,
		'cnnespanol.png': icon22,
		'comedy.png': icon23,
		'comet.png': icon24,
		'cooking.png': icon25,
		'cozitv.png': icon26,
		'cspan.png': icon27,
		'cspan2.png': icon28,
		'cw.png': icon29,
		'dcw50.png': icon30,
		'discovery.png': icon31,
		'disney.png': icon32,
		'e.png': icon33,
		'epix.png': icon34,
		'epix2.png': icon35,
		'epix3.png': icon36,
		'espn.png': icon37,
		'espn2.png': icon38,
		'espncl.png': icon39,
		'espnd.png': icon40,
		'espnnews.png': icon41,
		'espnu.png': icon42,
		'familygekijyo.png': icon43,
		'food.png': icon44,
		'fox.png': icon45,
		'foxbus.png': icon46,
		'foxnews.png': icon47,
		'france24.png': icon48,
		'freeform.png': icon49,
		'fs1.png': icon50,
		'fsdet.png': icon51,
		'fsoh.png': icon52,
		'fx.png': icon53,
		'fxm.png': icon54,
		'fyi.png': icon55,
		'golf.png': icon56,
		'h&i.png': icon57,
		'hallmark.png': icon58,
		'hallmarkmovies.png': icon59,
		'hbo.png': icon60,
		'hbo2.png': icon61,
		'hbocom.png': icon62,
		'hbofam.png': icon63,
		'hbosig.png': icon64,
		'hbozo.png': icon65,
		'hdnmv.png': icon66,
		'hgtv.png': icon67,
		'history.png': icon68,
		'hln.png': icon69,
		'hunansatellite.png': icon70,
		'ifc.png': icon71,
		'ion.png': icon73,
		'justice.png': icon74,
		'lifetime.png': icon75,
		'lmn.png': icon76,
		'marriott.png': icon77,
		'marriottBonvoyTV_Logo.png': icon78,
		'mbc.png': icon79,
		'metv.png': icon80,
		'mlb.png': icon81,
		'mnt.png': icon82,
		'msg.png': icon83,
		'msnbc.png': icon84,
		'mtv.png': icon85,
		'mtv2.png': icon86,
		'mtvLive.png': icon87,
		'my20.png': icon88,
		'my9.png': icon89,
		'nasa.png': icon90,
		'natgeo.png': icon91,
		'nba.png': icon92,
		'nbc.png': icon93,
		'nbcsp.png': icon94,
		'nbcsports.png': icon95,
		'nesn.png': icon96,
		'netflixSmall.png': icon97,
		'nfl.png': icon98,
		'nhl.png': icon99,
		'nick.png': icon100,
		'nicktoons.png': icon101,
		'ny1.png': icon102,
		'own.png': icon103,
		'oxygen.png': icon104,
		'palladia.png': icon105,
		'pbs.png': icon106,
		'phoenixinfonews.png': icon107,
		'rai.png': icon108,
		'science.png': icon109,
		'showtime.png': icon110,
		'showtime2.png': icon111,
		'showtimeextreme.png': icon112,
		'sny.png': icon113,
		'speed.png': icon114,
		'spike.png': icon115,
		'starz.png': icon116,
		'syfy.png': icon117,
		'tbs.png': icon118,
		'tcm.png': icon119,
		'telemundo.png': icon120,
		'thistv.png': icon121,
		'tlc.png': icon122,
		'tmc.png': icon123,
		'tnt.png': icon124,
		'travel.png': icon125,
		'tru.png': icon126,
		'tuff.png': icon127,
		'tvland.png': icon128,
		'twc.png': icon129,
		'unimas.png': icon130,
		'univision.png': icon131,
		'usa.png': icon132,
		'velocity.png': icon133,
		'vh1.png': icon134,
		'wapa.png': icon135,
		'wapa2.png': icon136,
		'we.png': icon137,
		'weta.png': icon138,
		'weta26.png': icon139,
		'wetakids.png': icon140,
		'wetauk.png': icon141,
		'wipr.png': icon142,
		'wmtj.png': icon143,
		'yes.png': icon144,
};
export default imageLookup;